
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner{
        color: #9A9A9A;
        font-size: 14px;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    .SetPromotion{
        .SetPromotionHeader{
            .headerLeft{
                display: flex;
                justify-content: space-between;
                p{
                    color: #333333;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1;
                    margin: 20px 0 0 0;
                }
                .headerLeftBtn{
                    .btn{
                        width: 98px;
                        height: 40px;
                        border-color: #FD4446;
                        line-height: 0;
                    }
                    /*.btn:nth-child(1){*/
                        /*background: #F7F9FB;*/
                        /*color: #FD4446;*/
                    /*}*/
                    .btn:nth-child(2){
                        background: #FD4446;
                        color: #ffffff;
                    }
                }
                /deep/ .el-button.is-disabled {
                    background-color: #DCDCDC !important;
                    border-color: #DCDCDC !important;
                    color: #fff !important;
                }
            }
        }
        .SetPromotionTable {
            /*min-height: calc(100vh - 232px);*/
            margin-top: 18px;
            background: #ffffff;
            padding: 20px;
            /*/deep/ .el-table {*/
            /*    min-height: calc(100vh - 272px);*/
            /*}*/
        }
    }
